import React from 'react';
import './ComicZone.css';


class ComicZone extends React.Component {

  componentDidMount() {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    this.checkSessionStorage();
  }

  checkSessionStorage = () => {
    let ableToView = sessionStorage.getItem('cykoHead')
    if (ableToView !== null) {
      if (ableToView !== "B5BEA41B6C623F7C09F1BF24DCAE58EBAB3C0CDD90AD966BC43A45B44867E12B") {
        window.location.replace('https://thetatv.thetadrop.com/'); //need to fix that
      }
    } else {
      window.location.replace('https://thetatv.thetadrop.com/');
    }

  }

  scrollToTop = () =>{
    window.scrollTo({
      top: 0
    });
  }




  render() {

    this.scrollToTop();

    return (
      <div className="comic-zone">
        <div className='overlay-comic'>
          <div className='comic-container'>
            <img className='comic-box' src='https://cykoheadz.com/gang/img/0.jpg' alt='page0' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/1.jpg' alt='page1' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/2.jpg' alt='page2' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/3.jpg' alt='page3' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/4.jpg' alt='page4' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/5.jpg' alt='page5' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/6.jpg' alt='page6' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/7.jpg' alt='page7' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/8.jpg' alt='page8' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/9.jpg' alt='page9' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/10.jpg' alt='page10' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/11.jpg' alt='page11' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/12.jpg' alt='page12' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/13.jpg' alt='page13' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/14.jpg' alt='page14' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/15.jpg' alt='page15' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/16.jpg' alt='page16' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/17.jpg' alt='page17' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/18.jpg' alt='page18' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/19.jpg' alt='page19' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/20.jpg' alt='page20' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/21.jpg' alt='page21' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/22.jpg' alt='page22' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/23.jpg' alt='page23' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/24.jpg' alt='page24' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/25.jpg' alt='page25' />
            <br />
            <img className='comic-box' src='https://cykoheadz.com/gang/img/26.jpg' alt='page26' />
          </div>
          <br></br>
          <div className='comic-close-button-wrapper'>
            <button className="close-button" onClick={() => { window.location.replace('./') }}>CLOSE</button>
          </div>
        </div>
      </div>
    );
  }
}

export default ComicZone;
