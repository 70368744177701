import React from 'react'
import { ThetaDropConnect } from '@thetalabs/theta-drop-connect';
import Footer from './Footer';
import './Gang.css';
import CykoHeadzLogo from './cyko-headz.png';
import LoggedInWelcome from './LoggedInWelcome';
import ComicZone from './ComicZone';
import Pong from './Pong';
import Blorto from './Blorto_graphic.png';
import axios from 'axios';
//new info
const infoID = 'dapp_fkt1j39u0u2wr6i44sf59hqs10h';
const redirectURLNew = 'https://gang.cykoheadz.com/'
const uriNew = "https://gang.cykoheadz.com" + '/thetadrop-auth-finished.html';
//https://gang.cykoheadz.com/


const redirectURL = 'https://' + 'cykoheadz.com' + '/thetadrop-auth-finished.html';

const cykoHeadzNFT = 'type_jgzuu9ypadin368adt3mtdix3wi';

let resultFromConnect = "Test";



class Gang extends React.Component {

  constructor(props) {
    super(props)
    this.thetaDrop = new ThetaDropConnect();

    let sessionUserName = sessionStorage.getItem('CykoUserName');

    this.comicScroll = React.createRef();

    this.state = {
      userName: sessionUserName,
      userData: null,
      isOwner: false,
      pfp: null,
      showComic: false,
      buttonText: "READ",
      playPong: false,
      pongButtonText: "PLAY",
      showDiscordButton : false
    }
  }

  scrollToElement = () => this.comicScroll.current.scrollIntoView();

  componentDidMount() {
    this.checkSessionStorage();
    // Optional: Use only if using the redirect option
    this.finishConnectViaRedirect();
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });


  }

  finishConnectViaRedirect = async () => {
    const result = await this.thetaDrop.finishConnectViaRedirect();

    if (result) {
      const { snsId, oauth2Token } = result;

      this.setState({
        tpsId: snsId,
        authToken: oauth2Token
      });

      this.refreshUser();
      this.refreshOwnershipChecks();

      const formData = new FormData();
      formData.append('sns_id', this.state.tpsId);
      formData.append("oauth2_token", this.state.authToken);
      axios.post('https://db1.zdev9.com/cykoDiscord/verify_user.php', formData).then(response => {
        if (response.data.success) {

          this.setState({ showDiscordButton: true });
          this.setState({discordLink: response.data.discord_url});
          sessionStorage.setItem("discordLink", response.data.discord_url);
        } else {
          this.setState({ showDiscordButton: false });
        }
      });
    }
  }

  refreshOwnershipChecks = async () => {
    const filters = {
      content_id: cykoHeadzNFT
    };
    const userInfo = await this.thetaDrop.fetchUserNFTs(filters);
    let nfts = userInfo.nfts;
    sessionStorage.setItem("nfts", JSON.stringify(nfts));

    const isOwner = await this.thetaDrop.checkUserIsOwner(filters);
    if (isOwner == true) {
      sessionStorage.setItem('cykoHead', 'B5BEA41B6C623F7C09F1BF24DCAE58EBAB3C0CDD90AD966BC43A45B44867E12B');
    } else {
      sessionStorage.setItem('cykoHead', 'FCBCF165908DD18A9E49F7FF27810176DB8E9F63B4352213741664245224F8AA');
    }

    this.setState({
      isOwner: isOwner
    });

  }

  //"type_jgzuu9ypadin368adt3mtdix3wi"

  refreshUser = async () => {
    const userData = await this.thetaDrop.fetchUser();
    window.sessionStorage.setItem('cykokoUserData', JSON.stringify(userData));
    this.setState({
      userData: userData
    });
    window.sessionStorage.setItem("cykoUserName", userData.username);
    this.setState({ userName: userData.username });
    window.sessionStorage.setItem("pfp", userData.avatar_url);
    this.setState({ pfp: userData.avatar_url });

  }

  connectToThetaDrop = async () => {
    const { snsId, oauth2Token } = await this.thetaDrop.connectViaPopup(infoID, redirectURLNew);

    this.setState({
      tpsId: snsId,
      authToken: oauth2Token
    });

    this.refreshUser();
    this.refreshOwnershipChecks();
  };

  connectToThetaDropViaRedirect = async () => {
    this.thetaDrop.connectViaRedirect(infoID, redirectURLNew);
  };

  checkSessionStorage = () => {
    let sessionUserName = sessionStorage.getItem('cykoUserName');
    if (sessionUserName == null) {
    } else {
      this.setState({ userName: sessionUserName });
    }
    let sessionTPSidJSON = sessionStorage.getItem('cykokoUserData');
    if (sessionTPSidJSON == null) {
    } else {
      let jsonData = JSON.parse(sessionTPSidJSON);
      this.setState({ tpsId: jsonData.tps_id });
    }
    let discordLink = sessionStorage.getItem('discordLink');
    if (discordLink !== null) {
      this.setState({"discordLink": discordLink});
      this.setState({showDiscordButton:true});
    } 
  }

  ComicToggle = () => {
    let ableToView = sessionStorage.getItem('cykoHead')
    if (ableToView !== null) {
      if (ableToView == "B5BEA41B6C623F7C09F1BF24DCAE58EBAB3C0CDD90AD966BC43A45B44867E12B") {
        window.location.replace('./comic')
      } else {
        this.setState({ buttonText: "Sorry you need to be an NFT owner to see this content" });
      }
    } else {
      window.location.replace('https://cykoko.thetadrop.com/');
    }
  }

  PongToggle = () => {
    let ableToView = sessionStorage.getItem('cykoHead')
    if (ableToView !== null) {
      if (ableToView == "B5BEA41B6C623F7C09F1BF24DCAE58EBAB3C0CDD90AD966BC43A45B44867E12B") {
        window.location.replace('./pong')
      } else {
        console.log("not onwer")
        this.setState({ pongButtonText: "Sorry you need to be an NFT owner to see this content" });
      }
    } else {
      window.location.replace('https://cykoko.thetadrop.com/');
    }
  }

  ConnectDiscord = (e) => {
    window.location.assign(this.state.discordLink);
  }

  render() {

    return (

      <div className='Gang-main'>
        {!this.state.userName && !this.state.playPong &&
          <div className='Gang-Top'>
            <div className='Gang-Top-content-container'>
              <div className='top-image-container'>
                <img src={CykoHeadzLogo} alt='Gang Logo' />
              </div>
              <div className='Gang-Text-Container'>
                <h1 className='Gang-Text-Title'>Gang members Only</h1>
                <h3 className='Gang-Text-Subtitle'>This is where you can get your exclusive Cyko Headz stuff.</h3>
                <button onClick={() => { this.connectToThetaDropViaRedirect() }}>CONNECT MY CYKO HEADZ</button>
              </div>
            </div>
          </div>
        }

        {this.state.userName && !this.state.showComic && !this.state.playPong &&
          <LoggedInWelcome
            DisplayName={this.state.userName}
            pfp={this.state.pfp}
            isOwner={this.state.isOwner}
          />
        }
        {
          !this.state.showComic && !this.state.playPong &&
          <div className='Gang-Second'>
            <div className='Gang-second-content'>
              <div className='Gang-second-text-container'>
                <h1>Stuff for your Headz</h1>
                <h3>Exclusive content for Cyko Headz Gang members!</h3>
              </div>
              <div className='Gang-reward-contanier'>
                <div className='Gang-reward'>
                  <div className='gang-reward-img-container'>
                    <img src='./img/cyko-ko-cover-7.png' alt='cover' />
                    <div className='gang-reward-text-container'>
                      <p><strong>Exclusive Theta Comic</strong></p>
                      <p><em>Cyko KO: Time to Get Kraken!</em></p>
                      <div className='reward-description-container'>
                        <p>Can Cyko KO, Peachy Keen and Meemop defeat the Kraken before...*gulp* the Dupliverse crashes into SuperEarth?!!</p>
                      </div>
                      <button onClick={() => { this.ComicToggle() }} className='read-button-text'>{this.state.buttonText}</button>
                      {
                        this.state.buttonText !== "READ" &&
                        <div style={{ textAlign: "center" }}>
                          <br></br>
                          <a href='https://cykoko.thetadrop.com'>
                            <button >GET NFT</button>
                          </a>
                        </div>

                      }
                    </div>
                  </div>
                </div>
                <div className='Gang-reward'>
                  <div className='gang-reward-img-container'>
                    <img src={Blorto} alt='Cyko Pong' />
                    <div className='gang-reward-text-container'>
                      <p><strong>CYKO HEADZ GAME</strong></p>
                      <p><em>Beat Blorto</em></p>
                      <div className='reward-description-container'>

                        <p>A battle game where you can use your Cyko Headz!</p>
                      </div>
                      <button onClick={(e) => this.PongToggle(e)}>{this.state.pongButtonText}</button>
                    </div>
                  </div>
                </div>
                <div className='Gang-reward'>
                  <div className='gang-reward-img-container'>
                    <img src='./img/coming-soon.png' alt='coming soon' />
                    <div className='gang-reward-text-container'>
                      <p><strong>CYKO KO ANIMATED SERIES</strong></p>
                      <p><em>TBA</em></p>
                      <div className='reward-description-container'>
                        <p>A series of selected animated Cyko KO micro episodes.</p>
                      </div>
                      <button>TBA</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          (this.state.userName && this.state.showDiscordButton) &&
          <div className='discord'>
            <h1>Exclusive Discord!</h1>
            <button className='discord-button' onClick={(e) => this.ConnectDiscord(e)}>JOIN</button>
          </div>
        }
        {this.state.showComic && !this.state.playPong &&
          <ComicZone
            closeComic={this.ComicToggle}
          />
        }
        {
          this.state.playPong &&
          <Pong />
        }
        {
          !this.state.playPong &&
          <Footer />
        }
      </div>
    )
  }
}

export default Gang