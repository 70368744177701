import React from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import './GameTesting.css'


let unityContext = new UnityContext({
  loaderUrl: "./../gameTesting/Youtubevideo.loader.js",
  dataUrl: "./../gameTesting/Youtubevideo.data",
  frameworkUrl: "./../gameTesting/Youtubevideo.framework.js",
  codeUrl: "./../gameTesting/Youtubevideo.wasm",
});

const checkIfOwner = () => {
  let ableToView = sessionStorage.getItem('cykoHead');
  let cykoAccount = sessionStorage.getItem('cykokoUserData')
    if (ableToView !== null && cykoAccount !== null) {
      if (ableToView == "B5BEA41B6C623F7C09F1BF24DCAE58EBAB3C0CDD90AD966BC43A45B44867E12B") {
      } else {
        window.location.replace('https://gang.cykoheadz.com/');
      }
    } else {
      window.location.replace('https://cykoko.thetadrop.com/');
    }
}


function GameTesting() {

  checkIfOwner();

  const ratio = window.innerWidth / window.innerHeight;
  //1+ landscape
  //less than 1 portaiot
  if (ratio < 1) {  ///Portrait
    return (
      <div className="pong-main">
        <div className="pong-container">
          <h1 style={{color: "white"}}>Error! Game is only meant to be played in landscape mode. Please rotate your device and refresh the page. </h1>
          <h2 style={{color: "white", fontSize:"18px"}}>Also once your in the game, it's reccomended you enable FullScreen Mode under 'Settings' For Best Experince.</h2>
        </div>
      </div>
    );
  } else {
    return (  //Good to go, device is landscape
      <div className="pong-main">
        <div className="testing-container">
          <Unity unityContext={unityContext} className="pong-game" />
        </div>
      </div>
    );
  }
}

export default GameTesting;
