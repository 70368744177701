import React, { Component } from 'react';
import './App.css';
import { ThetaDropConnect } from '@thetalabs/theta-drop-connect';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ComicZone from './ComicZone';
import Gang from './Gang';
import Pong from './Pong';
import DiscordRedirect from './DiscordRedirect';
import Zqhh from './Zqhh';
import GameTesting from './GameTesting';
import PacmanTesting from './PacmanTesting';
import SessionInfo from './SessionInfo';


class App extends React.Component {

    constructor(props) {
        super(props)
        this.thetaDrop = new ThetaDropConnect();

        this.state = {
            isOwner: false
        }
    }


    render() {
        return (
            <Router>
                <div className="App">
                    <Routes>
                        <Route exact path="/" element={<Gang/>} />
                        <Route exact path="/comic" element={<ComicZone/>} />
                        <Route exact path="/pong" element={<Pong/>} />
                        <Route exact path="/discordRedirect" element={<DiscordRedirect/>} />
                        <Route exact path="/zqhh" element={<Zqhh/>} />
                        <Route exact path="/gameDiscordTesting" element={<GameTesting/>} />
                        <Route exact path="/meep" element={<PacmanTesting/>} />
                        <Route exact path="/sessionInfo" element={<SessionInfo/>} />
                        <Route exact path="/*" element={<Gang/>} />
                    </Routes>
                </div>
            </Router>
        )
    }
}

export default App;