import React from 'react'
import './Gang.css';
import './DiscordRedirect.css';
import Headz from './cykoheadz.png';
import axios from 'axios';


class DiscordRedirect extends React.Component {

  constructor(props) {
    super(props)


    this.state = {
      message : "Loading..."
    }


  }

  componentDidMount = async () => {
    let url = (window.location.href);
    const myArray = url.split("=");
    const stateArray = myArray[1].split("&");

    const formData = new FormData();
    formData.append('code', stateArray[0]);
    formData.append("state", myArray[2]);
    let sessionTPSidJSON = sessionStorage.getItem('cykokoUserData');

    if (sessionTPSidJSON == null) {
    } else {
      let jsonData = JSON.parse(sessionTPSidJSON);
      formData.append("user_id", jsonData.tps_id); //tpsid
    }
    try {
      axios.post('https://db1.zdev9.com/cykoDiscord/verify_discord.php', formData).then(response => {
        console.log(response.data);
        this.setState({message : response.data.message})
      });
    } catch (err) {
      console.log(err);
    }
  }





  render() {

    return (

      <div className='discord-redirect-main'>
        <img src={Headz} />
        <h1>{this.state.message}</h1>
        <a href='./'>
          <button className='discord-redirect-main-home-button'>Home</button>
        </a>
      </div>
    )
  }
}

export default DiscordRedirect