import React from "react";
import Unity, { UnityContext } from "react-unity-webgl";


let unityContext = new UnityContext({
  loaderUrl: "./../pacman/Pacman.loader.js",
  dataUrl: "./../pacman/Pacman.data",
  frameworkUrl: "./../pacman/Pacman.framework.js",
  codeUrl: "./../pacman/Pacman.wasm",
});



const enableFullScreen = (e) =>{
  e.preventDefault();
  document.body.requestFullscreen();
}



function PacmanTesting() {

  return (
    <div className="pong-main">
      <div className="pong-container" id='game'>
      <Unity unityContext={unityContext} className="pong-game" />
      </div>
      <button onClick={(e)=>enableFullScreen(e)}>FullScreen</button>
    </div>
  );
}

export default PacmanTesting;
