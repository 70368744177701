import React, { Component } from 'react';
import './Footer.css';
import packageJson from '../../package.json';



const Footer = () => {
    return (
        <div className='Footer-main'>
            <h3 className='Footer-main-text'>© Copyright 2022 Rob Feldman. All Rights Reserved.</h3>
            <p>Site Version {packageJson.version}</p>
        </div>
    );
}

export default Footer;