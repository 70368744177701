import React from 'react';
import './LoggedInWelcome.css';

class LoggedInWelcome extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

    }


  }

  componentDidMount() {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

  }

  render() {
    return (
      <div className='Gang-Top'>
        <div className='Loggedin-Gang-Top-content-container'>
          <div className='LoggedIn-top-image-container'>
            {
              sessionStorage.getItem('pfp') !== "undefined" &&
              <div>
                <img src={sessionStorage.getItem('pfp')} alt='Gang Logo' className='pfp' />
              </div>
            }
          </div>
          <div className='Gang-Text-Container'>
            <h1 className='Gang-Text-Title'>Welcome, {this.props.DisplayName}</h1>
            <h3 className='Gang-Text-Subtitle'>This is where you can get your exclusive Cyko Headz stuff.</h3>
          </div>
        </div>
      </div>
    )
  }

}

export default LoggedInWelcome