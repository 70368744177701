import React from "react";
import Unity, { UnityContext } from "react-unity-webgl";
import './Pong.css';

let unityContext = new UnityContext({
  loaderUrl: "./../pong2/MobilePong4.loader.js",
  dataUrl: "./../pong2/MobilePong4.data",
  frameworkUrl: "./../pong2/MobilePong4.framework.js",
  codeUrl: "./../pong2/MobilePong4.wasm",
});


const checkIfOwner = () => {
  let ableToView = sessionStorage.getItem('cykoHead');
  let cykoAccount = sessionStorage.getItem('cykokoUserData')
    if (ableToView !== null && cykoAccount !== null) {
      if (ableToView == "B5BEA41B6C623F7C09F1BF24DCAE58EBAB3C0CDD90AD966BC43A45B44867E12B") {
      } else {
        window.location.replace('https://gang.cykoheadz.com/');
      }
    } else {
      window.location.replace('https://cykoko.thetadrop.com/');
    }
}


function Pong() {
  const ratio = window.innerWidth / window.innerHeight;

  checkIfOwner();

  if (ratio < 1) {  ///Portrait
    return (
      <div className="pong-main">
        <div className="pong-container">
          <h1 style={{color: "white"}}>Error! Game is only meant to be played in landscape mode. Please rotate your device and refresh the page. </h1>
          <h2 style={{color: "white", fontSize:"18px"}}>Also once you're in the game, it's recommended you enable FullScreen Mode under 'Settings' for the best experince.</h2>
        </div>
      </div>
    );
  } else {
    return (  //Good to go, device is landscape
      <div className="pong-main">
        <div className="testing-container">
          <Unity unityContext={unityContext} className="pong-game" />
        </div>
      </div>
    );
  }
}

export default Pong;
