import React from 'react';
import './ComicZone.css';


class SessionInfo extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
        userInfo : "None",
        NFTs: "None"
    }
}

  componentDidMount() {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    this.checkSessionStorage();
  }

  checkSessionStorage = () => {
    let ableToView = sessionStorage.getItem('cykokoUserData')
    let jsonInfo = JSON.parse(ableToView);
    this.setState({userInfo : ableToView});


    let nfts = sessionStorage.getItem('nfts')
    let jsonInfoNFTs = JSON.parse(nfts);
    this.setState({NFTs : nfts});
  }



  render() {

    return (
      <div style={{width: '50vw'}}>
        <h1>UserInfo!</h1>
        <h3>{this.state.userInfo}</h3>
        <br></br>
        <h1>NFTs!</h1>
        <h3>{this.state.NFTs}</h3>
      </div>
    );
  }
}

export default SessionInfo;
